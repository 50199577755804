



















































































































































@import '@design';

.single-location--header {
  @media only screen and (max-width: 321px) {
    .v-btn {
      height: 38px;
      padding: 10px !important;
    }
  }
}

.single-location {
  .featured-image {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }
}
